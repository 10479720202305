.Tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.Tab span {
  padding: 12px;
}
