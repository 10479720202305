.MainBar {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.Bar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}
.Logo {
  display: flex;
  align-self: center;
  flex-grow: 1;
  height: 5vmin;
}
.Bar-title {
  display: flex;
  align-self: center;
  font-weight: bold;
  font-size: 22px;
}
.Bar-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.Bar-button:focus {
  border: 1px solid paleturquoise;
}

.Content-open {
  width: 50vw;
  padding: 48px;
  transition: width 0.25s ease-in;
  box-shadow: inset 15px 0 25px -25px black;
  font-size: 18px;
  overflow-y: auto;
}
.Content-closed {
  width: 0;
  transition: width 0.15s ease-out;
  overflow: hidden;
}
