.Content {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.Content-mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.TabsContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}
.Tabs {
  display: flex;
  flex-direction: row;
}
.Section-mobile {
  padding: 44px 16px 44px 16px;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  font-size: 24px;
}
