body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Muli', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, 'Big Shoulders Text',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.underline {
  background-image: linear-gradient(to right, #1de9b6, #006064);
  background-position: 0 1.04em;
  background-repeat: repeat-x;
}

a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  font-weight: bolder;
}

ul {
  margin: 12px 0 6px 0;
}
