@import url(https://fonts.googleapis.com/css?family=Big+Shoulders+Text|Muli&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Muli', 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: -apple-system, BlinkMacSystemFont, 'Big Shoulders Text',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

span.underline {
  background-image: -webkit-gradient(linear, left top, right top, from(#1de9b6), to(#006064));
  background-image: linear-gradient(to right, #1de9b6, #006064);
  background-position: 0 1.04em;
  background-repeat: repeat-x;
}

a {
  color: white;
  font-weight: bold;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  font-weight: bolder;
}

ul {
  margin: 12px 0 6px 0;
}

.App {
  background-color: #37474f;
  height: 100vh;
  color: white;
}

.Tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.Tab span {
  padding: 12px;
}

.Content {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.Content-mobile {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.TabsContainer {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 200;
}
.Tabs {
  display: flex;
  flex-direction: row;
}
.Section-mobile {
  padding: 44px 16px 44px 16px;
  display: flex;
  flex-grow: 1;
  overflow-y: auto;
  font-size: 24px;
}

.MainBar {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.Bar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px;
}
.Logo {
  display: flex;
  align-self: center;
  flex-grow: 1;
  height: 5vmin;
}
.Bar-title {
  display: flex;
  align-self: center;
  font-weight: bold;
  font-size: 22px;
}
.Bar-button {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
.Bar-button:focus {
  border: 1px solid paleturquoise;
}

.Content-open {
  width: 50vw;
  padding: 48px;
  -webkit-transition: width 0.25s ease-in;
  transition: width 0.25s ease-in;
  box-shadow: inset 15px 0 25px -25px black;
  font-size: 18px;
  overflow-y: auto;
}
.Content-closed {
  width: 0;
  -webkit-transition: width 0.15s ease-out;
  transition: width 0.15s ease-out;
  overflow: hidden;
}



